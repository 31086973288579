<template>
  <div>
    <v-row v-if="errorMessages">
      <v-col v-if="errorMessages" cols="12">
        <no-data :firstText="errorMessages" with-button></no-data>
      </v-col>
    </v-row>
    <div v-else>
      <v-row align="">
        <v-col cols="12">
          <app-stats @scroll="scrollTo" :loading="loader"></app-stats>
        </v-col>
      </v-row>
      <v-row class="mb-12">
        <v-col cols="12">
          <app-charts></app-charts>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-timeline :info="appInfo" :app_id="appID"></app-timeline>
        </v-col>
      </v-row>
    </div>
    <SidePanel
      v-model="releaseDrawer"
      width="720"
      @close="releaseDrawer = false"
    >
      <create-release-step
        v-if="releaseDrawer"
        :app-info="appInfo"
        @released="
          $router.push({ name: 'releases', params: { app_id: appInfo.id } })
        "
        @close="(releaseDrawer = false), $emit('close')"
      />
    </SidePanel>
  </div>
</template>

<script>
import AppStats from "@/view/components/App/Dashboard/AppStats.vue";
import { mapGetters } from "vuex";
import AppCharts from "@/view/components/App/Dashboard/AppCharts.vue";
import CreateReleaseStep from "@/view/components/Releases/CreateRelease.vue";
import AppTimeline from "@/view/components/App/Timeline.vue";

export default {
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppCharts,
    AppStats,
    AppTimeline,
    CreateReleaseStep,
  },
  data() {
    return {
      appID: "",
      releaseDrawer: false,
      errorMessages: "",
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
  },
  created() {
    this.appID = this.$route.params.app_id;
  },
  methods: {
    scrollTo(item) {
      if (item === "install") {
        this.$router.push({ name: "app-installs" });
      } else {
        this.$router.push({ name: "app-members" });
      }
    },
  },
};
</script>

<style scoped>
</style>
